<template>
	<div>
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Rules
		</h1>
		<div v-if="forms_ready">
			<q-list v-for="form in forms" :key="form.id">
				<q-item tag="label" class="list-element q-mx-sm q-my-md row">
					<div class="word-break col-9 text-left">
						Rules for {{form.name}}
					</div>
					<div class="col-3 flex justify-end">
						<q-btn
							flat
							round
							color="dark"
							:icon="!form.is_inherited ? 'o_edit' : 'o_visibility'"
							@click="goToEdit(form.id)"
						/>
					</div>
				</q-item>
			</q-list>
		</div>
	</div>
</template>

<script>
import Rule from '@/components/incidents/rules/Rule.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "RulesList",
	components: {
		Rule,
	},
	data() {
		return {
			rules_local: [],
			add_new_rule: false,
			type_status: "Status",
			type_field: "Field",
			new_rule: {
				conditions: {},
				actions: {},
				form: ""
			},
			scenarios: [],
			history_of_the_rule: "",
			is_disabled: false,
			forms_ready: false
		};
	},
	computed: {
		...mapGetters(['rules', 'forms', 'logged_user', 'current_CG']),
	},
	methods: {
		...mapActions([ "listForms" ]),
		goToEdit(form_id){
			this.$router.push({ name: 'Edit Rule', params: {id: form_id} });
		},
	},
	async created() {
		this.$q.loading.show({
			delay: 400
		})
		await this.listForms();
		for (let i in this.forms){
			this.forms[i].is_inherited = this.current_CG.customer_group.parent && this.forms[i].customer_group !== this.logged_user.customer_group
		}
		this.forms_ready = true
		this.$q.loading.hide()
	}
};
</script>
<style lang="scss" scoped>
	.list-element {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $grey-4;
		background: $grey-1;
		font-size: 1rem;
		flex-direction: row;
		text-align: left;
		border-radius: 4px;
		&:hover {
			background-color: $grey-2;
		}
	}
	.word-break{
		word-break: break-word;
	}
</style>
